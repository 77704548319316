import React, { useEffect } from 'react';
import p5 from 'p5';

const Sketch = () => {
  useEffect(() => {
    const sketch = (p) => {
      let dots = [];
      const hoverRadius = Math.min(window.innerWidth, window.innerHeight) * 0.3
      const dotCount = Math.round(window.innerWidth * window.innerHeight / 12000); // Adjust this divisor as needed
      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight).parent('sketch');
        for (let i = 0; i < dotCount; i++) {
          dots.push({
            x: p.random(p.width),
            y: p.random(p.height),
            vx: p.random(-1, 1), // Adjusted for varied velocity
            vy: p.random(-1, 1),
          });
        }
      };

      p.draw = () => {
        p.background(255); // White background
        dots.forEach(dot => {
          let distanceToMouse = p.dist(p.mouseX, p.mouseY, dot.x, dot.y);

          // Update dot position with constrained random movement
          dot.x += dot.vx;
          dot.y += dot.vy;

          // Gently steer dots back towards the center if they drift too far
          if (dot.x < 0 || dot.x > p.width) dot.vx *= -1;
          if (dot.y < 0 || dot.y > p.height) dot.vy *= -1;

          // Slow down movement when near the mouse
          if (distanceToMouse < hoverRadius) {
            dot.vx *= 0.9;
            dot.vy *= 0.9;
          }

          // Draw dot
          p.fill(201, 204, 63); // Yellow-green color for dots
          p.noStroke();
          p.ellipse(dot.x, dot.y, 7, 7);

          // Connect dots when the mouse is near
          dots.forEach(otherDot => {
            if (dot !== otherDot) {
              let dotDistance = p.dist(dot.x, dot.y, otherDot.x, otherDot.y);
              if (dotDistance < 150 && distanceToMouse < hoverRadius) {
                p.stroke(180, 196, 36, 200);
                p.line(dot.x, dot.y, otherDot.x, otherDot.y);
              }
            }
          });
        });
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };
    };

    let myp5 = new p5(sketch);

    return () => myp5.remove();
  }, []);

  return <div id="sketch" />;
};

export default Sketch;
