import React from 'react';
import dataAnalysisImg from '../svgs/danalysis.svg'; 
import awsImg from '../svgs/aws.svg'; 
import consultingImg from '../svgs/consulting.svg'; 
import datavizImg from '../svgs/dataviz.svg'; 
import extractionImg from '../svgs/extraction.svg'; 
import webdevImg from '../svgs/webdev.svg'; 
import customsolImg from '../svgs/custom.svg'; 
import machineImg from '../svgs/machine.svg'; 
import mgmtImg from '../svgs/management.svg'; 

// Directly defined ServiceCard, no export needed as it's used only in this file.
const ServiceCard = ({ title, description, img }) => (
  <div className="flex flex-col items-center text-center p-4 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out">
    <img src={img} class="size-32 object-contain"></img>
    <h3 className="text-lg font-semibold">{title}</h3>
    <p>{description}</p>
  </div>
);

// ServicesGrid component, which we will export for use in other components.
export const ServicesGrid = () => {
  // Placeholder data for the services
  const services = [
    { 

      img: dataAnalysisImg,
      title: 'Data Analysis',
      description: 'Unlock insights from your data with our advanced data analysis techniques.'
    },
    {
      img: machineImg,
      title: 'Machine Learning',
      description: 'Harness the power of machine learning for predictive analytics and automation.'
    },
    {
      img: webdevImg,
      title: 'Web Development',
      description: 'Build responsive and interactive web applications tailored to your needs.'
    },
    {
      img: datavizImg,
      title: 'Data Visualization',
      description: 'Transform complex data into clear and compelling visualizations.'
    },
    {
      img: consultingImg,
      title: 'Consulting Services',
      description: 'Get guidance and recommendations for your data-driven projects.'
    },
    {
      img: extractionImg,
      title: 'Data Extraction',
      description: 'Extract, Transform and Load with us.'
    },
    {
      img: mgmtImg,
      title: 'Database Management',
      description: 'Indexing, CRUD operations, and more. Efficiently handle all your database needs.'
    },
    {
      img: awsImg,
      title: 'Amazon Web Services',
      description: 'Let us help you unlock the full potential of the cloud with AWS.'
    },
    {
      img: customsolImg,
      title: 'Custom Solutions',
      description: 'Tailor-made solutions to address your unique business challenges.'
    }
  ];
  
  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.map((service, index) => (
          <ServiceCard key={index} img={service.img} title={service.title} description={service.description} />
        ))}
      </div>
    </div>
  );
};
