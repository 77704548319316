import React from 'react';
import { Link } from '@nextui-org/react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import ModalButton from './ModalButton'; // Import the CyclingWords component
import '../css/Footer.css'; // Make sure this points to the correct file path
import datalogo from "../svgs/logo.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-block logo-block">
          {/* Replace with your actual logo */}
          <img src={datalogo} alt="Datamorphosis logo" className="footer-logo"/>
        </div>
        
        <div className="footer-links">
            <div className="footer-block">
                <h6 className="footer-title">RESOURCES</h6>
                <Link href="#" className="footer-link">Datamorphosis</Link>
            </div>
            <div className="footer-block">
                <h6 className="footer-title">FOLLOW US</h6>
                <Link href="https://github.com" className="footer-link">Github</Link>
                <Link href="https://linkedin.com" className="footer-link">Linkedin</Link>
            </div>
            <div className="footer-block">
                <h6 className="footer-title">LEGAL</h6>
                <Link href="#" className="footer-link">Terms & Conditions</Link>
                <ModalButton />

            </div>
        </div>
        
        </div>
      
      <div className="footer-divider"></div>
      <div className="footer-bottom">
        <span className="footer-bottom-text">&copy; 2024 Datamorphosis. All rights reserved. <Link href="#" className="footer-bottom-link">Data Policy</Link></span>
        <div className="social-icons mr-8">
          <Link href="https://x.com/Datamorphosis" className="social-link">𝕏</Link>
          <Link href="https://github.com" className="social-link"><FaGithub /></Link>
          <Link href="https://linkedin.com" className="social-link"><FaLinkedin /></Link>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
