import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Sketch from './Sketch'; // Import the Sketch component

const words = ["DATA", "PEOPLE", "PROCESSES", "STORIES"];

const CyclingWords = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWordIndex((currentIndex) => (currentIndex + 1) % words.length);
        }, 3000); // Change the word every 3 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const wordAnimationVariants = {
        initial: { y: 30, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.5 } },
        exit: { y: -30, opacity: 0, transition: { duration: 0.5 } }
    };

    return (
        <div style={{ position: 'relative', width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ zIndex: 10, position: 'relative', marginBottom: '30px' }}>
                <p style={{ fontWeight: 700, fontSize: '40px' }}>WE.</p>
                <p style={{ fontWeight: 700, fontSize: '40px', fontStyle: 'bold' }}>TRANSFORM.</p>
            </div>
            <AnimatePresence mode="wait">
                <motion.div
                    key={words[currentWordIndex]}
                    variants={wordAnimationVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{ zIndex: 2, position: 'relative', fontSize: '70px', fontWeight: 600 }}
                >
                    {words[currentWordIndex]}
                </motion.div>
                <Sketch /> {/* This renders the p5 sketch as a background */}

            </AnimatePresence>
        </div>
        
    );
};

export default CyclingWords;
