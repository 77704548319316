import React, { useState } from 'react';
import FormButton from './FormButton'
function Popup({ message, onClose }) {
  return (
    <div className="popup-overlay">
      <div className="popup-content p-4 rounded-md w-64 h-128 text-center">
        <p className="text-[#000000] text-xl font-medium mb-4">{message}</p>
        <button
          className="btn button button--primary py-2 px-4 rounded-md"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function FormHandler() {
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      message: formData.get('message'),
    };

    fetch('/budget_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      setPopupMessage(data.popupMessage); // Adjust according to your API response
      setShowPopup(true);
      e.target.reset();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <form onSubmit={handleSubmit} method="POST">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Your Name</label>
          <input type="text" id="name" name="name" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">Your Email</label>
          <input type="email" id="email" name="email" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700">Your Message</label>
          <textarea id="message" name="message" rows="4" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required></textarea>
        </div>

        {/* <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 w-full">Get Started</button> */}
        <FormButton />
      </form>
    </div>
  );
}

export default FormHandler;
