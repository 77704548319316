import React from "react";
import {Button} from "@nextui-org/react";
import {Tooltip} from "@nextui-org/react";  

export default function App() {
  return (
    <Tooltip content=":D" color="primary]">
        <Button color="primary" type="submit">
        Submit
        </Button>
    </Tooltip>
  );
}
