import React from 'react';
import { Card } from '@nextui-org/react';
import { motion } from 'framer-motion';

// Define the animation variants
const cardVariants = {
  hidden: { opacity: 0, translateY: 50 },
  visible: { opacity: 1, translateY: 0 },
};

// Define the card content
const cards = [
  {
    emoji: "👨‍🔬",
    title: "Interdisciplinary Excellence",
    content: "Our diverse backgrounds allow us to approach challenges from multiple angles, resulting in comprehensive and innovative solutions.",
  },
  {
    emoji: "🤓",
    title: "Student Perspective",
    content: "We're not just consultants; we are lifelong learners. As students, we are on a perpetual quest for knowledge and innovation, which we bring to every project.",
  },
  {
    emoji: "🌟",
    title: "Affordable Expertise",
    content: "We understand the budget constraints faced by students and small businesses. Datamorphosis offers flexible pricing to ensure our skills are accessible to all.",
  },
];

const AnimatedCards = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', justifyText: 'center', alignContent:'center', flexWrap: 'wrap', gap: '20px', padding: '20px'}}>
      {cards.map((card, index) => (
        <motion.div
          key={index}
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
          style={{ width: '100%', maxWidth: '300px', padding: '20px', margin:"20px",display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', background: 'white', borderRadius: '8px' }}
        >
          <Card isHoverable css={{ mw: "100%" }}>
            <div style={{ textAlign: 'center', fontSize: '48px', margin: '20px' }}>{card.emoji}</div>
            <div style={{ fontSize: '20px', fontWeight: 'bold', margin: '20px' }}>{card.title}</div>
            <div style={{ textAlign: 'center', margin: '20px'  }}>{card.content}</div>
          </Card>
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedCards;
