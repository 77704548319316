  import React from 'react';

  import './css/App.css';

  import NavigationBar from './components/NavigationBar';
  import AnimatedCards from './components/AnimatedCards';
  import CyclingWords from './components/CyclingWords'; 
  import {Input, NextUIProvider} from "@nextui-org/react";
  import ContactButton from './components/ContactButton';
  import Footer from './components/Footer';
  import { ServicesGrid } from './components/ServicesGrid';
  import BudgetForm from './components/BudgetForm';
  import Sketch from './components/Sketch';
  import FormHandler from './components/FormHandler'
  import projecto from "./svgs/project.svg"


  function App() {
    return (
      <NextUIProvider>
      <NavigationBar />
      
      <div className="App box-border">
        <header className="App-header first-screen-container">
          <Sketch />
          <CyclingWords />
        </header>

        <h1 class="text-5xl font-bold mb-8 pt-8">Why Choose Datamorphosis?</h1>
        <div><AnimatedCards /></div>
        <h1 class="text-5xl font-bold mb-8">Our services</h1>
        <ServicesGrid />

      </div>

      <section id="contact-and-budget" class="bg-gray-100 py-12">
        <div class="container mx-auto text-center">
          <h1 class="text-5xl font-bold mb-8">Get Started with Your Project</h1>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div class="bg-gray-100 rounded-lg mb-4"><img src={projecto} style={{backgroundSize: "50%;"}}></img></div>

            <div class="bg-white p-6 rounded-lg shadow-md">
              <h2 class="text-xl font-bold mb-4">Tell Us About Your Project</h2>
              <p class="text-gray-600 mb-4">Excited about your project idea? So are we! Let's get started on your journey. Fill out the form below, and we'll be in touch.</p>
              {/* <form onSumbit="budgetPost()" method="POST">
                <div class="mb-4">
                  <label for="name" class="block text-gray-700">Your Name</label>
                  <input type="text" id="name" name="name" class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required></input>
                </div>

                <div class="mb-4">
                  <label for="email" class="block text-gray-700">Your Email</label>
                  <input type="email" id="email" name="email" class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required></input>
                </div>

                <div class="mb-4">
                  <label for="message" class="block text-gray-700">Your Message</label>
                  <textarea id="message" name="message" rows="4" class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" required></textarea>
                </div>
                </form> */}

                {/* <BudgetForm /> */}
                {/* <ContactButton /> */}


                <FormHandler />
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {/* <Footer /> */}
      </NextUIProvider>

    );
  }

  export default App;
